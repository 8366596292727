import { axiosInstance } from "src/api";
import {
  EvseStartRequest,
  EvseStopRequest,
  StationChangeAvailabilityRequest,
  StationChangeAvailabilityResponse,
  StationComponentsResponse,
  StationConfigRequest,
  StationConfigResponse,
  StationDetailsResponse,
  StationResetRequest,
  StationSummaryFilter,
  StationSummaryResponse,
  StationTimeSeriesFilter,
  StationUpsertRequest,
  StationUptimeResponse,
} from "src/api/stations/interface";

export const getStationSummaries = (filter: StationSummaryFilter) =>
  axiosInstance.get<Array<StationSummaryResponse>>("/station", { params: filter });

export const getStation = (id: string) => axiosInstance.get<StationDetailsResponse>(`/station/${id}`);

export const upsertStation = (data: StationUpsertRequest) =>
  axiosInstance.put<StationDetailsResponse>(`/station/${data.id}`, {
    ...data,
  });

export const postEvseStartCharging = (data: EvseStartRequest) =>
  axiosInstance.post(`/station/${data.id}/start`, {
    ...data,
  });

export const postEvseStopCharging = (data: EvseStopRequest) =>
  axiosInstance.post(`/station/${data.id}/stop`, {
    ...data,
  });

export const postStationReset = (data: StationResetRequest) =>
  axiosInstance.post(`/station/${data.id}/reset`, {
    ...data,
  });

export const postStationStart = (id: string) =>
  axiosInstance.post(`/station/${id}/debug/remote_start`, {
    token: "dashboard",
  });

export const putStartSimulator = (id: string) => axiosInstance.put(`/station/${id}/debug/simulator`, {});

export const deleteStopSimulator = (id: string) => axiosInstance.delete(`/station/${id}/debug/simulator`, {});

export const postConnectSimulator = (id: string, connectorId: string) =>
  axiosInstance.post(`/station/${id}/debug/simulator/${connectorId}/connect`);

export const postDisconnectSimulator = (id: string, connectorId: string) =>
  axiosInstance.post(`/station/${id}/debug/simulator/${connectorId}/disconnect`);

export const postStationChangeAvailability = (data: StationChangeAvailabilityRequest) =>
  axiosInstance.post<StationChangeAvailabilityResponse>(`/station/${data.id}/availability`, {
    ...data,
  });

export const getStationConfiguration = (data: StationConfigRequest) =>
  axiosInstance.get<StationConfigResponse>(`/station/${data.id}/configuration`);

export const putStationConfiguration = (data: StationConfigRequest) =>
  axiosInstance.put<StationConfigResponse>(`/station/${data.id}/configuration`, {
    ...data,
  });

export const getStationTypes = () => axiosInstance.get<StationComponentsResponse>("/config/station/components");

export const getStationUptime = (id: string, params: StationTimeSeriesFilter) =>
  axiosInstance.get<StationUptimeResponse>(`/station/${id}/time-series/uptime`, { params });
