import { Card, CardContent, Grid, useTheme } from "@mui/material";
import { FC } from "react";
import { EvseDetailsResponse } from "src/api/stations/interface";
import { Can } from "src/services/permissions/context";
import EvseCardConnectorInfo from "./EvseCardConnectorInfo";
import EvseCardSlider from "./EvseCardSlider";
import EvseCardTariffInfo from "./EvseCardTariffInfo";
import EvseCardTitle from "./EvseCardTitle";

const EvseCard: FC<{ stationId: string; evse: EvseDetailsResponse }> = ({ stationId, evse }) => {
  const { palette } = useTheme();
  return (
    <Card style={{ backgroundColor: "#F0F3F6" }} elevation={0}>
      <CardContent>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <EvseCardTitle evse={evse} stationId={stationId} />
          </Grid>

          {evse.activeTariffId && (
            <Can I="read" a="Tariff">
              <Grid item>
                <EvseCardTariffInfo evse={evse} />
              </Grid>
            </Can>
          )}

          <Grid item>
            <Card style={{ backgroundColor: palette.grey[200] }} elevation={0}>
              <CardContent style={{ paddingBottom: 0 }}>
                <Grid container direction="column">
                  <Grid item>
                    <EvseCardConnectorInfo stationId={stationId} evse={evse} />
                  </Grid>

                  <Can I="debug" a="Station">
                    <Grid item>
                      <EvseCardSlider stationId={stationId} evse={evse} />
                    </Grid>
                  </Can>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default EvseCard;
