import { LoaderStatus } from "@googlemaps/js-api-loader";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { Box } from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";

const popUpId = "clustered-map-info-window";

interface ClusteredMapProps {
  loaderStatus: LoaderStatus;
  pins: google.maps.LatLngLiteral[];
  popUps: React.ReactElement[];
}

const ClusteredMap: FC<ClusteredMapProps> = ({ loaderStatus, pins, popUps }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [bounds, setBounds] = useState<google.maps.LatLngBounds>();
  const [map, setMap] = useState<google.maps.Map>();
  const [popUp, setPopUp] = useState<google.maps.InfoWindow>();
  const maxDefaultZoom = 11;

  useEffect(() => {
    if (loaderStatus === LoaderStatus.SUCCESS && ref.current && !map) {
      const newMap = new window.google.maps.Map(ref.current, {
        disableDefaultUI: true,
        fullscreenControl: true,
        zoomControl: true,
        zoom: maxDefaultZoom,
        center: new google.maps.LatLng(1.3521, 103.8198),
        mapId: process.env.REACT_APP_GOOGLE_MAP_ID,
      });
      setBounds(new google.maps.LatLngBounds());
      setMap(newMap);
      setPopUp(new google.maps.InfoWindow({ minWidth: 100 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaderStatus, ref, map]);

  const createMarkerIcon = () => {
    const markerIcon = document.createElement("img");
    markerIcon.src = "/maps/marker.svg";
    return markerIcon;
  };

  const createClusterMarkerIcon = (count: number) => {
    const clusterMarkerIcon = document.createElement("img");
    clusterMarkerIcon.src = "/maps/cluster-marker.svg";
    clusterMarkerIcon.style.position = "relative";
    clusterMarkerIcon.style.zIndex = "1";

    const clusterMarkerContent = document.createElement("div");
    clusterMarkerContent.style.position = "relative";
    clusterMarkerContent.style.display = "grid";
    clusterMarkerContent.style.placeItems = "center";

    const label = document.createElement("div");
    label.style.position = "absolute";
    label.style.zIndex = "2";
    label.style.fontSize = "14px";
    label.style.color = "#8700FF";
    label.textContent = String(count);

    clusterMarkerContent.appendChild(clusterMarkerIcon);
    clusterMarkerContent.appendChild(label);
    return clusterMarkerContent;
  };

  useEffect(() => {
    if (map && bounds) {
      const initMap = async () => {
        const { AdvancedMarkerElement } = (await google.maps.importLibrary("marker")) as google.maps.MarkerLibrary;

        const markers: google.maps.marker.AdvancedMarkerElement[] = [];
        pins.forEach((position, index) => {
          const marker = new AdvancedMarkerElement({
            position,
            map,
            content: createMarkerIcon(),
          });
          markers.push(marker);
          bounds.extend(marker.position!);
          marker.addListener("click", () => {
            const content = (
              <Box id={popUpId} style={{ marginRight: "20px" }}>
                {popUps[index]}
              </Box>
            );
            const isPopUpOpen = !!document.getElementById(popUpId);
            const isClickingSelf = popUp?.getPosition()?.equals(new google.maps.LatLng(position));
            if (isPopUpOpen && isClickingSelf) {
              popUp?.close();
            } else {
              popUp?.setContent(ReactDOMServer.renderToString(content));
              popUp?.open({ anchor: marker, map });
            }
          });
        });
        map.fitBounds(bounds);

        const cluster = new MarkerClusterer({
          markers,
          map,
          renderer: {
            render: ({ count, position }) =>
              new AdvancedMarkerElement({
                position,
                zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
                content: createClusterMarkerIcon(count),
              }),
          },
        });
        google.maps.event.addListener(map, "click", () => popUp?.close());
        google.maps.event.addListener(cluster, "click", () => popUp?.close());
        // In situations where there is only 1 marker or they are concentrated in
        // a small area, we want the zoom to stay in a wide enough area to show
        // adjacent locations.
        google.maps.event.addListenerOnce(map, "bounds_changed", () => {
          const zoom = map.getZoom();
          map.setZoom(!zoom || zoom > maxDefaultZoom ? maxDefaultZoom : zoom);
        });
      };
      initMap();
    }
  }, [map, popUp, pins, popUps, bounds]);

  return <div ref={ref} id="map" style={{ width: "100%", height: "100%", borderRadius: "12px" }} />;
};

export default ClusteredMap;
