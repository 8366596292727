import { Power, PowerOff } from "@mui/icons-material";
import { Grid, IconButton, SvgIcon, Tooltip, useTheme } from "@mui/material";
import dayjs from "dayjs";
import { FC } from "react";
import { useIntl } from "react-intl";
import { postConnectSimulator, postDisconnectSimulator } from "src/api/stations";
import { EvseDetailsResponse } from "src/api/stations/interface";
import { ReactComponent as PowerIcon } from "src/assets/images/power-icon.svg";
import { notifyAxiosError, notifyInfo } from "src/components/notifications";
import ability from "src/services/permissions/ability";
import ConnectorIcon from "../ConnectorIcon";

const EvseCardConnectorInfo: FC<{ stationId: string; evse: EvseDetailsResponse }> = ({ stationId, evse }) => {
  const { palette } = useTheme();
  const intl = useIntl();

  const connectSimulator = (connectorId: string) => {
    postConnectSimulator(stationId, connectorId)
      .then(() => {
        notifyInfo("stationDebug_connectSimulator", intl);
      })
      .catch((err) => notifyAxiosError(err, intl));
  };

  const disconnectSimulator = (connectorId: string) => {
    postDisconnectSimulator(stationId, connectorId)
      .then(() => {
        notifyInfo("stationDebug_disconnectSimulator", intl);
      })
      .catch((err) => notifyAxiosError(err, intl));
  };

  return (
    <Grid container justifyContent="space-between">
      <Grid item>
        <Grid container spacing={1}>
          <Grid item>
            <SvgIcon component={PowerIcon} inheritViewBox fontSize="medium" sx={{ color: palette.grey[500] }} />
          </Grid>

          <Grid item color={palette.grey[500]}>
            {intl.formatMessage({ id: "evseDetails_maxPowerXxx" }, { xxx: evse.specifications.powerMaxWattage / 1000 })}
          </Grid>

          <Grid item color={palette.grey[500]}>
            {evse.specifications.powerType.split("_")[0]}
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid container spacing={1} alignItems="center">
          {evse.connectors.map((connector) => (
            <Grid item key={`${evse.id}-${connector.id}-connector-icon`}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <ConnectorIcon
                    kind={connector.specifications.kind}
                    status={connector.status}
                    statusOcpp={connector.statusOcpp}
                    statusUpdatedAt={dayjs(connector.statusUpdatedAt)}
                    evseStatus={evse.status}
                  />
                </Grid>

                {ability.can("debug", "Station") && (
                  <Grid item>
                    <Tooltip title={intl.formatMessage({ id: "stationDebug_connectSimulator" })}>
                      <IconButton onClick={() => connectSimulator(connector.id)}>
                        <Power fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={intl.formatMessage({ id: "stationDebug_disconnectSimulator" })}>
                      <IconButton onClick={() => disconnectSimulator(connector.id)}>
                        <PowerOff fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EvseCardConnectorInfo;
